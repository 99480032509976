<template>
	<el-form :model="form" label-width="105px" class="form">
		<div class="subTit"><i class="el-icon-caret-bottom" />企业简介</div>
		<div class="center">
			<el-form-item label="关键词:">
				<el-input v-model="form.keywords" />
			</el-form-item>
			<el-form-item label="描述:">
				<el-input type="textarea" :rows="3" v-model="form.description" />
			</el-form-item>
			<el-form-item label="LOGO图片">
				<el-upload class="avatar-uploader" :action="picUrl"
					:show-file-list="false" v-loading.fullscreen.lock="loading" element-loading-text="拼命加载中……"
					:on-progress="change" 
					:before-upload="beforeAvatarUpload"
					element-loading-background="rgba(250, 250, 250, 0.5)" :show-file-list="false"
					:data="fileInfo" :on-success="handleAvatarSuccess" 
					
					accept="image/jpeg,image/png">
					<img v-if="imageUrl" :src="imageUrl" :onerror="imgSrc" class="avatar">
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
				<!-- 新增加 -->
				<!-- <div class="vue-cropper-box" >
				      <div class="vue-cropper-content">
				        <vueCropper ref="cropper"
							:img="option.img"
							:outputSize="option.outputSize"
							:outputType="option.outputType"
							:info="option.info"
							:canScale="option.canScale"
							:autoCrop="option.autoCrop"
							:autoCropWidth="option.autoCropWidth"
							:autoCropHeight="option.autoCropHeight"
							:fixed="option.fixed"
							:fixedNumber="option.fixedNumber">
				        </vueCropper>
				      </div>
				    </div> -->
				<div class="tishinews">
					小提示：上传图片只能是JPG/PNG格式的正方形且上传图片大小不能超过 5MB！
				</div>
			</el-form-item>
			<el-form-item label="内容">
				<tinyMce :content="form.content" v-model="form.content" :change.sync="form.content" />
				<!-- <vue-tinymce v-model="form.content"  :change.sync="form.content" /> -->
			</el-form-item>
		</div>
		<div class="submit-btn" @click="handleSubmit">保存</div>
	</el-form>
</template>

<script>
	import {shops,setup} from '@/assets/js/tinymceSt.js'
	import tinyMce from '@/components/plug/tinyMce_shop'
	export default {
		data() {
			return {
				loading:false,
				imgSrc: 'this.src="' + require('../../assets/img/mr.jpg') + '"',
				form: {
					keywords: '',
					description: '',
					logo: '',
					content: ''
				},
				imageUrl: '',
				shops,
				setup,
				fileInfo: {
					type: "Member"
				},
				content: [{
					validator: (rule, value, callback) => {
						if (this.form.content === '') {
							callback(new Error('请输入详细内容'));
						} else {
							callback();
						}
					}
				}],
				picUrl:""
			}
		},
		created() {
			this.$axios.post('/getSellerLanmuContent', {
				lm_id: 1
			}).then(data => {
				for (let i in this.form) {
					if (data[i]) {
						this.form[i] = data[i]
					}
				}
				this.imageUrl = data.logoUrl ? data.logoUrl :''
			})
			this.picUrl = this.$store.state.BASE_URL+'/uploadImgOne.html'
		},
		methods: {
			
			change(event, file, fileList){
				this.loading = true;
			},
			handleAvatarSuccess(res, file) {
				this.handleAvatar(res, file,0)
				this.loading = false;
			},
			//上传图片并限制大小宽高
			beforeAvatarUpload (file) {
				  //let _this = this
				  const isJPG = file.type === 'image/jpeg';
				  const isPNG = file.type === 'image/png';
				  const isLt5M = file.size / 1024 / 1024 < 5;
				  if (!isJPG && !isPNG) {
				  	this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
				  }
				  if (!isLt5M) {
				  	this.$message.error('上传头像图片大小不能超过 5MB!');
				  }
				  const isSize = new Promise(function (resolve, reject) {
				    let _URL = window.URL || window.webkitURL
				    let img = new Image()
				    img.onload = function () {
				     let  valid = img.width === img.height
				      valid ? resolve() : reject()
				    }
				    img.src = _URL.createObjectURL(file)
				  }).then(() => {
				    return file
				  }, () => {
					  this.$message.error('上传图片需要为正方形!');
					  return Promise.reject()
				  })
				  return (isJPG || isPNG) && isLt5M && isSize;
			},
			handleSubmit() {
				this.form.lm_id = 1
				this.form.seller_id = this.$store.state.SELLER_ID
				this.$axios.post('/setSellerLanmuContent', this.form).then(data => {
					if (data && data.code == 400005) {
						this.$message.error(data.msg)
						return
					}
					this.$message.success('设置成功')
				})
			}
		},
		components: {
			tinyMce
		}
	}
</script>

<style scoped>
	.form>>>.el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
	.tishinews{
		color: #666ee8;
	}
</style>
